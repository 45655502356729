/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"

import PageHeader from "./header"
import PageFooter from "./footer"

class Layout extends React.Component<{ children: any }> {
  componentDidMount() {
    const isProduction = process.env.NODE_ENV === "production"
    const isCanonical = window.location.hostname.startsWith("www.")
    const isAmplify = window.location.hostname.endsWith(".amplifyapp.com")

    if (isProduction && !isCanonical && !isAmplify) {
      let u = new URL(window.location.href)
      u.port = ""
      u.hostname = "www." + u.hostname
      window.location.href = u.toString()
    }
  }

  // Inject `site` prop to children
  injectedChildren(data: any) {
    return React.Children.map(this.props.children, (child, i) => {
      return React.cloneElement(child, {
        site: data.site.siteMetadata,
      })
    })
  }

  render() {
    const query = graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `

    return (
      <StaticQuery
        query={query}
        render={data => (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PageHeader siteTitle={data.site.siteMetadata.title} />
            <main
              style={{
                flexGrow: 1,
              }}
            >
              <Container fixed maxWidth="md">
                <Box pt={4} pb={4}>
                  {this.injectedChildren(data)}
                </Box>
              </Container>
            </main>
            <footer>
              <PageFooter />
            </footer>
          </div>
        )}
      ></StaticQuery>
    )
  }
}

export default Layout

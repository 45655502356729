import React from "react"

import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import HomeIcon from "@material-ui/icons/Home"
import HelpIcon from "@material-ui/icons/Help"

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  homeButton: {
    marginLeft: -12,
    marginRight: 20,
  },
}

class PageHeader extends React.Component<{ classes: any; siteTitle: string }> {
  render() {
    const { classes } = this.props

    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={classes.homeButton}
            color="inherit"
            aria-label="Home"
            href="/"
          >
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {this.props.siteTitle}
          </Typography>
          <IconButton
            className={classes.homeButton}
            color="inherit"
            aria-label="About"
            href="/about/"
          >
            <HelpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(styles)(PageHeader)

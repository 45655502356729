import React from "react"

import { withStyles } from "@material-ui/core/styles"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import GetAppIcon from "@material-ui/icons/GetApp"
import ListIcon from "@material-ui/icons/List"
import EmailIcon from "@material-ui/icons/Email"

const styles = {
  root: {
    bottom: 0,
    width: "100%",
  },
}

class PageFooter extends React.Component<{ classes: any }> {
  state = {
    value: 0,
  }

  handleChange = (_: React.ChangeEvent<{}>, value: number) => {
    this.setState({ value })
  }

  feedback = () => {
    const mail = atob("bWFpbHRvOnN1cHBvcnRAd2hhbGVkb3duLmNvbQ==")

    window.open(mail)
  }

  render() {
    const { classes } = this.props
    const { value } = this.state

    return (
      <BottomNavigation
        value={value}
        onChange={this.handleChange}
        showLabels
        className={classes.root}
        color="primary"
      >
        <BottomNavigationAction
          label="Get WhaleDown"
          icon={<GetAppIcon />}
          href="https://www.whaledown.com"
          target="_blank"
        />
        <BottomNavigationAction
          label="Supported Sites"
          icon={<ListIcon />}
          href="/sites/"
          target="_blank"
        />
        <BottomNavigationAction
          id="feedback"
          label="Feedback"
          icon={<EmailIcon />}
          onClick={this.feedback}
        />
      </BottomNavigation>
    )
  }
}

export default withStyles(styles)(PageFooter)
